<div>
  @for (role of roles.slice(0, rolesCenterIndex); track role) {
    <div class="communication-role">
      <ng-container *ngTemplateOutlet="roleTemp; context: { $implicit: role }"></ng-container>
    </div>
  }
</div>
<div>
  @for (role of roles.slice(rolesCenterIndex); track role) {
    <div class="communication-role">
      <ng-container *ngTemplateOutlet="roleTemp; context: { $implicit: role }"></ng-container>
    </div>
  }
</div>

<ng-template
  #roleTemp
  let-role
>
  <div
    (click)="changeSelection(role)"
    class="d-flex"
  >
    <app-checkbox
      (change)="changeSelection(role)"
      [checked]="isRoleSelected(role)"
      [disabled]="disabled"
    ></app-checkbox>
    <div class="communication-role_label">
      <span>
        {{ role.title | translate }}
      </span>
      @if (!compactMode) {
        <span class="communication-role_number-of-emp"> ({{ role.numberOfEmployees || 0 }}) </span>
      }
    </div>
  </div>
  @if (!disabled && !compactMode && !role.disabled && canInviteUsers) {
    <button
      (click)="onPlusClick($event)"
      [appFocusable]
      class="communication-role_plus-btn"
    >
      +
    </button>
  }
</ng-template>
